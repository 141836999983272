import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import EventList from "./eventList"

const EventsIndex = (props) => {
  return (
    <Layout theme="light">
      <SEO title="Watershed Events" />
      <Hero heroTitle="Events" theme="light" graph="radar" />
      <EventList events={props.events} />
    </Layout>
  )
}

export default EventsIndex
