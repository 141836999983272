import React from "react"
import { graphql } from "gatsby"
import EventsTemplate from "../../templates/events"

export const query = graphql`
  query {
    allSanityEvent(sort: {fields: eventDate, order: ASC}) {
      edges {
        node {
          _id
          title
          url
          eventDate
          category { title }
          thumbnail {
            asset { url }
          }
          excerpt
        }
      }
    }
  }
`

const EventsPage = (props) => {
  const events = props.data.allSanityEvent.edges.map(({node}) => node)
  const d = new Date()
  const today = `${new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)}`

  const upcomingEvents = events.filter(event => {
    return (event.eventDate >= today && event.category.title === "Conference")
  })

  return (
    <EventsTemplate events={upcomingEvents} />
  )
}

export default EventsPage
