import React from "react"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"

import styles from "../../components/postListItem.module.scss"

const EventList = (props) => {
  return (
    <section>
      <PostWrapper>
        {props.events.length ? (
          <PostList events={props.events} theme="brandMid" />
        ) : (
          <div className={styles.postEmpty}>
            <p>Sorry, no events match that criteria.</p>
          </div>
        )}
        <PostSidebar theme="brandMid" type="event" />
      </PostWrapper>
    </section>
  )
}

export default EventList
